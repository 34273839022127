export const options = [
    { text: "Realtor", value: "1" },
    { text: "Appointment", value: "2" },
    { text: "Others", value: "3" },
]
export const optionsMethodPay = [
    { text: "Credit Card", value: "1" },
    { text: "Others", value: "2" },
]


export default  {options, optionsMethodPay}